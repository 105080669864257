import React, { Component } from 'react';

//* HOCs
import { withRouter } from 'next/router'

//* Helpers
import _ from 'lodash'

//* JSONs
import Languages from '../../translations';

const LanguageContext = React.createContext();

class LanguageProvider extends Component {
    state = {
        selectedLang: this.props.router.locale || this.props.selectedLang || process.env.REACT_APP_DEFAULT_LANGUAGE,
        languages: { ...Languages }
    }

    methods = {
        selectLanguage: this.selectLanguage.bind(this),
        translate: this.translate.bind(this)
    }

    componentDidUpdate() {
        if (this.props.selectedLang !== this.state.selectedLang) {
            this.selectLanguage(this.props.selectedLang)
        }
    }

    getLanguageParams(lang) {
        return this.state.languages[lang].params
    }

    selectLanguage(lang) {
        this.setState({ selectedLang: lang }, () => {
            localStorage.setItem('lang', lang)
        })
    }

    translate(val, vars) {
        const compiled = (_.isString(val) && this.state.languages[this.state.selectedLang][val]) && _.template(this.state.languages[this.state.selectedLang][val]);

        return (compiled && compiled(vars)) || val || 'Please Set Trnaslate Value'
    }

    render() {
        return (
            <LanguageContext.Provider value={{ ...this.state, ...this.methods }}>
                {this.props.children}
            </LanguageContext.Provider>
        )
    }
}

export default withRouter(LanguageProvider)
export const LanguageConsumer = LanguageContext.Consumer;