//* Config & API
import cfApi from '../api/cfApi'
import laravelApi from '../api/laravelApi'
import config from '../config.json'

export const getPageParams = ({ lang, router }) => getRouteObjFromConfig(lang, router)

export async function getData({ lang, state, router, getPageParams = false }) {
    const pageParams = getRouteObjFromConfig(lang, router);
    let newState = { ...state }
    let promiseObj = {}

    //! Checking existing data and preparing new promises
    if (!state.global?.[lang]) {
        promiseObj.global = getGlobal(lang)
    }

    if (pageParams) {
        if (pageParams.apiSlug && pageParams.autoload && !state.pages?.[pageParams.name]?.[lang]) {
            promiseObj.pages = getPage(lang, pageParams)
        }

        if (pageParams.cfContentType && !state.cf?.[pageParams.cfContentType]?.[lang]) {
            promiseObj.cf = getContentTypePosts(lang, pageParams.cfContentType, pageParams.cfParams)
        }

        if (pageParams.cfContentType2 && !state.cf?.[pageParams.cfContentType2]?.[lang]) {
            promiseObj.cf2 = getContentTypePosts(lang, pageParams.cfContentType2, pageParams.cfParams)
        }

        if (pageParams?.singleSlug && pageParams.apiPostSlug && pageParams.autoload && router.query.slug && !state?.[pageParams.singleType]?.[router.query.slug]?.[lang]) {
            promiseObj.singleItem = getPostIndividual(`${pageParams.apiPostSlug}/${router.query.slug}`, lang)
        }
    }

    //! Getting not existing data if new promises exists
    const promises = Object.values(promiseObj)

    promises.length > 0 && await Promise.all(promises).then(res => {
        //! Merging new data and existing data
        const keys = Object.keys(promiseObj)

        keys.forEach((el, k) => {
            const type = el === 'pages' ? 'name' : el === 'cf' ? 'cfContentType' : null
            newState = updateState({
                lang,
                stateKey: el == 'singleItem' ? pageParams?.apiPostType : el,
                response: res[k],
                type: el === 'singleItem' ? router.query.slug : type && pageParams[type],
                state: newState
            })
        })
    })

    let err = false
    if (pageParams?.singleSlug) {
        newState = pageParams.cfContentType ? getCFSinglePost(lang, newState, pageParams.cfContentType, pageParams.singleSlug) : newState

        //! Handling Not Found Single Page
        if (!newState.singleItem?.[pageParams.singleSlug] && !newState?.[pageParams.apiPostType]?.[pageParams.singleSlug]) {
            router.res.status(404)

            err = true

            if (typeof window !== 'undefined') {
                router.res.writeHead(302, { Location: '/404' })
                router.res.end()
            }
        }
    }

    newState.error = err || !pageParams

    return getPageParams ? { newState, pageParams } : newState
}

const getPage = (lang, pageParams) => {
    return laravelApi.getPage({ pageSlug: pageParams.apiSlug, lang }).then(page => {
        return page.data
    })
}

const getGlobal = (lang) => {
    return laravelApi.getGlobal({ lang }).then(res => {
        return res.data
    })
}

const getPostIndividual = (slug, lang, queryParams = {}) => {
    return laravelApi.getSinglePost({ postSlug: `${slug}`, lang, queryParams }).then((res) => {
        return res.data
    })
}

const getContentTypePosts = (lang, cfContentType, cfParams) => {
    return cfApi.getContentTypePosts(cfContentType, cfParams, lang).then(res => {
        return res
    })
}

const getCFSinglePost = (lang, state, cfContentType, slug) => {
    const posts = state.cf?.[cfContentType]?.[lang]?.items
    const singleItem = posts[slug]
    const nextPost = getNextCFPost(posts, slug)

    return { ...state, singleItem: { [slug]: singleItem && { ...singleItem, nextPost } } }
}

const getNextCFPost = (postsObj, slug) => {
    const keys = Object.keys(postsObj);
    let next = keys.filter((s, i) => slug === keys[i - 1])
    next = next.length ? next : keys[0]

    return postsObj[next]
}

const getRouteObjFromConfig = (lang, router) => {
    let path = router.asPath.split('?')[0]

    const regex = new RegExp(`^\/${lang}`, '')
    path = path.replace(regex, '')

    if (router.query.slug) {
        path = path.replace(router.query.slug + '/', '') + ':slug'
    }
    if (router.query.category) {
        path = path.replace(router.query.category + '/', ':category/')
    }

    const removedLastPath = path.substr(0, path.lastIndexOf('/'))

    let route = JSON.parse(
        JSON.stringify(
            Object.values(config.routes.default).filter((r) => {
                const pathArr = r.path.split(':')

                return r.path === path || r.path.replace(/\/$/, '') === path.replace(/\/$/, '') || (pathArr[0].replace(/\/$/, '') === removedLastPath && pathArr[1] && r.autoload)
            })[0] || {}
        )
    )

    route =
        router.query.slug || router.query?.params?.length === 2
            ? {
                ...route,
                singleSlug: router.query.slug,
                selectedSlug: router.query.slug ? router.query.slug : router.query?.params?.length === 2 && router.query.params[1],
            }
            : route

    return router.asPath === '404' ? false : route
}

const updateState = ({ state, lang, stateKey, response, type }) => {
    const newState = {
        ...state,
        [stateKey]: type
            ?
            {
                ...state?.[stateKey],
                [type]: {
                    ...state?.[stateKey]?.[type],
                    [lang]: response
                }
            }
            :
            { ...state[stateKey], [lang]: response }
    }

    return newState
}