import React, { memo as Memo, useRef, useState, useEffect } from 'react'
import { gsap } from 'gsap'

//* HOCs
import withUIContext from '@context/consumerHOC/UIConsumer'

//* Helpers
import Translate from '../../../Translate'

const Burger = Memo(props => {
    //! Refs
    const contRef = useRef()
    const tl = useRef();

    //! States
    const [act, setAct] = useState(false)

    //! Animations
    useEffect(() => {
        tl.current = gsap
            .timeline({
                paused: true
            })
            .to(contRef.current, 1, { strokeDashoffset: 600, ease: "power4.out" })
    }, [])

    useEffect(() => {
        act ? tl.current.play() : tl.current.reverse()
    }, [act, tl])

    return (
        <div className={`hamburger ${(props.lightHeader === 'reversed' && !props.checked) ? 'reversed' : (props.checked || props.lightHeader) ? "white" : ''}`} onClick={props.onClick} onMouseEnter={() => setAct(true)} onMouseLeave={() => setAct(false)}>
            <Translate val={props.checked ? "close" : "menu"} />
            <div className="burgerLine">
                <svg className="svg-max" viewBox="65.2 14.9 231 99" preserveAspectRatio="none">
                    <path ref={contRef} d="M225.7,92c0,0-169,49.6-157.8-9.1S283.1-2.9,293,35.1c9.9,37.9-66.8,76.1-66.8,76.1" fill="none" />
                </svg>
            </div>
        </div>
    )
})
export default withUIContext(Burger, ['darkMode'])