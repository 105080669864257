import React, { memo as Memo } from 'react';
import CustomLink from '../../../CustomLink'

const Default = Memo(props => {
    return (
        <CustomLink url={props.path} content={props.text}/>
    )
})

export default Default
