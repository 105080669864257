import React, { memo as Memo } from 'react';
import CustomLink from '../../../CustomLink'

const Nav = Memo(props => {
    return (
        <CustomLink className={"Link"+props.className} url={props.path} content={props.children} nav={true} />
    )
})

export default Nav
