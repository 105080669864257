import React, { memo as Memo, useEffect, useState, useMemo, useCallback } from 'react';
import { TweenLite } from 'gsap'

//* HOCs
import withDataContext from '@context/consumerHOC/DataConsumer'
import withUIContext from '@context/consumerHOC/UIConsumer'
import withLanguageContext from '@context/consumerHOC/LanguageConsumer'

//* Components
import Logo from '../../global/Logo/'
import MenuComponent from './HeaderComponent/MenuComponent'
import Burger from './HeaderComponent/Burger'

const Header = Memo(({ global, selectedLang, ...props }) => {
    //! States
    const [checked, setChecked] = useState(false);
    const [opened, setOpened] = useState(false);
    const [show, setShow] = useState(true);

    //! Global data
    const globalData = useMemo(() => global?.[selectedLang], [global, selectedLang])

    useEffect(() => {
        if (checked && !opened) {
            setOpened(true)
            document.body.classList.add('modal-open')
        }
        else if (!checked && opened) {
            setTimeout(() => {
                setOpened(false)
                document.body.classList.remove('modal-open')
            }, 600)
        }
    }, [checked, opened])

    const handleClick = () => {
        setChecked(!checked);
    }

    const onLogoClick = useCallback(() => {
        setChecked(false);
    }, [])

    useEffect(() => {
        window.addEventListener('scroll', onScroll)

        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    }, [])

    let oldScroll = 0
    const onScroll = useCallback(() => {
        if (window.scrollY > oldScroll && window.scrollY > 0 && !opened) {
            setShow(false)
        }
        else {
            setShow(true)
        }
        oldScroll = window.scrollY
    }, [opened])

    useEffect(() => {
        setChecked(false);
        setOpened(true)

        setOpened(false)
        document.body.classList.remove('modal-open')
    }, [selectedLang])

    useEffect(() => {
        TweenLite.to('.Header', 0.3, { y: show ? '0%' : '-100%' })
    }, [show])

    return globalData && (
        <header className={`header-container`}>
            <div className={`header-inner-cont ${opened ? 'mixed' : ''}`}>
                <div className="Header">

                    <Logo isMobileView={props.isMobileView} opened={opened} lightHeader={true} onClick={onLogoClick} />

                    <Burger checked={checked} lightHeader={props.lightHeader} onClick={handleClick} />

                </div>
            </div>

            <MenuComponent onClick={handleClick} opened={opened} content={globalData.contact_information} />
        </header>
    )
})

export default withLanguageContext(withUIContext(withDataContext(Header, ['global']), ['lightHeader']), ['selectedLang'])