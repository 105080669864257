import React, { forwardRef, useContext } from "react";
import { DataContext } from "../providers/DataProvider";
import getContext from "../../helpers/getContext";

const withDataContext = (ConnectedComponent, properties = []) => {
    
    return  forwardRef((props, ref) => {
        return (
            <DataContext.Consumer>
                {context => {
                    let contextProps = properties.length ? getContext(context, properties) : {};
                    
                    // ConnectedComponent.getInitialProps && ConnectedComponent.getInitialProps()
                    

                    return <ConnectedComponent {...contextProps} {...props} ref={ref} />
                }}
            </DataContext.Consumer>
        );
    })
}

export default withDataContext
